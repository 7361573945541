<template>
    <div class="item pointer-disabled">
        <div class="qb__question-wrapper">
            <div class="qb__question">
                <div class="qb__question-drag">
                    <i class="fa fa-ellipsis-h qb__question-drag-icon" />
                    <i class="fa fa-ellipsis-h qb__question-drag-icon" />
                </div>
                <div>
                    <div>
                        <span v-b-tooltip.hover title="Remove" class="qb__question-remove-icon-wrapper">
                            <i class="fa fa-remove qb__question-remove-icon pointer-action" @click="removeQuestion" />
                        </span>
                    </div>

                    <wit-input-group
                        :value="addQuestionTypes.find(el => el.id === $v.question.type.$model)"
                        type="select"
                        input-label="Type"
                        :options="addQuestionTypes"
                        :allow-empty="false"
                        @input="
                            value => {
                                $v.question.type.$model = value.id
                                $v.question.defaultValue.$model = this.question.multiple ? [] : null
                                emitInput()
                            }
                        "
                        :input-col="10"
                    />

                    <wit-input-group
                        v-model="$v.question.variableName.$model"
                        type="text"
                        input-label="Variable name"
                        placeholder="Name"
                        @input="emitInput"
                        :input-col="10"
                        :model="$v.question.variableName"
                        invalid="This field is required"
                        valid="Name is valid"
                    />

                    <wit-input-group
                        v-model="$v.question.title.$model"
                        type="text"
                        input-label="Question Title"
                        placeholder="Title"
                        @input="emitInput"
                        :input-col="10"
                        :model="$v.question.title"
                        invalid="This field is required"
                        valid="Title is valid"
                    />

                    <wit-input-group
                        v-model="$v.question.helpText.$model"
                        type="text"
                        input-label="Help Text"
                        placeholder="Help Text"
                        @input="emitInput"
                        :input-col="10"
                        :model="$v.question.helpText"
                        invalid="This field is required"
                        valid="Text is valid"
                    />

                    <wit-input-group
                        v-model="$v.question.isAdvanced.$model"
                        type="checkbox"
                        input-label="Is advanced"
                        @input="emitInput"
                        checked="Yes"
                        unchecked="No"
                    />

                    <div v-if="!['RESOURCE'].includes(question.type)">
                        <wit-input-group
                            v-model="$v.question.defaultValue.$model"
                            :type="variableType"
                            input-label="Default value"
                            placeholder="Default"
                            @input="emitInput"
                            :input-col="10"
                            :model="$v.question.defaultValue.$model ? $v.question.defaultValue : undefined"
                            label="label"
                            :options="questionOptions"
                            invalid="Value is invalid"
                            valid="Value is valid"
                        />
                    </div>

                    <div v-if="['LIST', 'RESOURCE'].includes(question.type)">
                        <wit-input-group
                            v-model="$v.question.multiple.$model"
                            type="checkbox"
                            input-label="Multiple"
                            @input="emitInput"
                            checked="On"
                            unchecked="Off"
                        />
                    </div>

                    <div v-if="['NUMBER', 'LIST', 'RESOURCE'].includes(question.type)">
                        <b-form-group :label-cols="2" :horizontal="true">
                            <template v-slot:label>
                                Range
                                <span v-b-tooltip.hover title="You can specify neither, one of the two or both.">
                                    <i class="fa fa-question-circle" />
                                </span>
                            </template>
                            <b-row>
                                <b-col md="10">
                                    <div class="qb__range">
                                        <div>
                                            <b-form-input
                                                v-if="
                                                    ['LIST', 'RESOURCE'].includes(question.type) && !question.multiple
                                                "
                                                placeholder="Minimum"
                                                v-model="disabledValue"
                                                type="number"
                                                disabled
                                            ></b-form-input>
                                            <b-form-input
                                                v-else
                                                placeholder="Minimum"
                                                v-model="$v.question.min.$model"
                                                type="number"
                                                @input="
                                                    value => {
                                                        value
                                                            ? ($v.question.min.$model = Number(value))
                                                            : ($v.question.min.$model = null)
                                                        emitInput()
                                                    }
                                                "
                                            ></b-form-input>
                                            <Feedback
                                                :state="validateRef(`question.min`)"
                                                invalid="This field must be smaller than maximum"
                                                valid="Value is valid"
                                            ></Feedback>
                                        </div>
                                        <i class="fa fa-long-arrow-right qb__range-arrow" />
                                        <div>
                                            <b-form-input
                                                v-if="
                                                    ['LIST', 'RESOURCE'].includes(question.type) && !question.multiple
                                                "
                                                placeholder="Maximum"
                                                v-model="disabledValue"
                                                type="number"
                                                disabled
                                            ></b-form-input>
                                            <b-form-input
                                                v-else
                                                placeholder="Maximum"
                                                v-model="$v.question.max.$model"
                                                type="number"
                                                @input="
                                                    value => {
                                                        value
                                                            ? ($v.question.max.$model = Number(value))
                                                            : ($v.question.max.$model = null)
                                                        emitInput()
                                                    }
                                                "
                                            ></b-form-input>
                                            <Feedback
                                                :state="validateRef(`question.max`)"
                                                invalid="This field must be greater than minimum"
                                                valid="Value is valid"
                                            ></Feedback>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-form-group>
                    </div>

                    <div v-if="question.type === 'LIST'">
                        <b-form-group label="Choices" :label-cols="2" :horizontal="true">
                            <b-row>
                                <b-col>
                                    <b-btn @click="addChoice" variant="primary">Add Choice</b-btn>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="10">
                                    <draggable
                                        v-model="$v.question.choices.$model"
                                        handle=".qb__choice-drag"
                                        @input="emitInput"
                                    >
                                        <choice
                                            v-for="choice in choiceItems"
                                            v-model="$v.question.choices.$each.$iter[choice.index].$model"
                                            :valid.sync="choicesValid[choice.uuid]"
                                            @remove="removeChoice"
                                            :key="choice.uuid"
                                            @input="
                                                () => {
                                                    setChoices()
                                                    emitInput()
                                                }
                                            "
                                            @update:valid="emitUpdate"
                                        />
                                    </draggable>
                                </b-col>
                            </b-row>
                        </b-form-group>
                    </div>

                    <div v-if="question.type === 'RESOURCE'">
                        <wit-input-group
                            v-model="$v.question.filterBy.$model"
                            type="select"
                            input-label="Filter by"
                            @input="
                                value => {
                                    if (['Type', 'Subtype'].includes(value)) {
                                        $v.question.filter.$model = []
                                    } else {
                                        $v.question.filter.$model = ['/name/i']
                                    }
                                    emitInput()
                                }
                            "
                            :options="filterByOptions"
                            :allow-empty="false"
                            :input-col="10"
                        />

                        <wit-input-group
                            v-if="['Type', 'Subtype'].includes(question.filterBy)"
                            v-model="$v.question.filter.$model"
                            type="treeselect"
                            input-label="Resource filter"
                            @input="emitInput"
                            :options="resourceFilterOptions"
                            :input-col="10"
                        />

                        <wit-input-group
                            v-else
                            v-model="$v.question.filter.$model[0]"
                            type="text"
                            input-label="Resource filter regex"
                            @input="emitInput"
                            :input-col="10"
                        />
                    </div>

                    <div v-if="question.type === 'TABLE'">
                        <wit-input-group
                            v-model="$v.question.tableFilter.$model"
                            type="text"
                            input-label="Only include tables with names including"
                            placeholder="Table filter (optional)"
                            @input="emitInput"
                            :input-col="10"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Draggable from 'vuedraggable'
import {validationMixin} from 'vuelidate'
import {required, requiredIf} from 'vuelidate/lib/validators'

import Feedback from '@/components/Feedback.vue'
import WitInputGroup from '@/components/Inputs/WitInputGroup.vue'

import {collectTypes} from '@/shared/collectTypes'
import {processTypes} from '@/shared/processTypes'
import {reportTypes} from '@/shared/reportTypes'
import {currencies} from '@/shared/currencies'

const isEqual = require('fast-deep-equal')

export default {
    components: {
        Choice: () => import('./Choice.vue'),
        Draggable,
        Feedback,
        WitInputGroup,
    },
    props: {
        value: {
            required: true,
        },
        valid: {
            type: Boolean,
        },
    },
    mixins: [validationMixin],
    validations: {
        question: {
            uuid: {},
            variableName: {required},
            type: {required},
            title: {required},
            helpText: {},
            min: {
                valid: function(_, value) {
                    return value.type === 'NUMBER' && ![null, ''].includes(value.min) && ![null, ''].includes(value.max)
                        ? value.min < value.max
                        : true
                },
            },
            max: {
                valid: function(_, value) {
                    return value.type === 'NUMBER' && ![null, ''].includes(value.min) && ![null, ''].includes(value.max)
                        ? value.min < value.max
                        : true
                },
            },
            multiple: {},
            choices: {
                minLength: function() {
                    return this.question.type === 'LIST' ? this.question.choices.length > 0 : true
                },
                $each: {
                    valid: function(choice) {
                        return Boolean(this.choicesValid[choice.uuid])
                    },
                },
            },
            filterBy: {
                required: requiredIf(function() {
                    return this.question.type === 'RESOURCE'
                }),
            },
            filter: {
                valid: function() {
                    return this.question.type === 'RESOURCE' ? this.question.filter.length > 0 : true
                },
            },
            tableFilter: {},
            isAdvanced: {},
            defaultValue: {
                valid: function(_, value) {
                    if (this.question && value && value.defaultValue) {
                        if (!this.question.multiple) {
                            if (this.question.type === 'TEXT') {
                                return value.defaultValue && value.defaultValue.length > 0
                            }
                            if (this.question.type === 'DATE') {
                                return value.defaultValue && !isNaN(new Date(value.defaultValue))
                            }
                            if (this.question.type === 'NUMBER') {
                                return (
                                    value.defaultValue &&
                                    ((this.question.min && value.defaultValue >= this.question.min) ||
                                        !this.question.min) &&
                                    ((this.question.max && value.defaultValue <= this.question.max) ||
                                        !this.question.max)
                                )
                            }
                            if (['LIST'].includes(this.question.type)) {
                                return Boolean(value.defaultValue)
                            }
                            if (this.question.type === 'CURRENCY') {
                                return (
                                    value.defaultValue &&
                                    currencies.findIndex(el => el.code === value.defaultValue.id) > -1
                                )
                            }
                            if (this.question.type === 'TIMEZONE') {
                                return (
                                    value.defaultValue &&
                                    this.questionOptions.findIndex(el => el.id === value.defaultValue.id) > -1
                                )
                            }
                        } else {
                            if (['LIST'].includes(this.question.type) && this.question.multiple) {
                                return (
                                    value.defaultValue &&
                                    (!this.question.multiple ||
                                        (this.question.multiple &&
                                            ((this.question.min && value.defaultValue.length >= this.question.min) ||
                                                !this.question.min) &&
                                            ((this.question.max && value.defaultValue.length <= this.question.max) ||
                                                !this.question.max)))
                                )
                            }
                        }
                    }
                    return true
                },
            },
        },
    },
    data() {
        return {
            addQuestionModel: {
                id: 'TEXT',
                label: 'Text',
            },
            addQuestionTypes: [
                {
                    id: 'TEXT',
                    label: 'Text',
                },
                {
                    id: 'NUMBER',
                    label: 'Number',
                },
                {
                    id: 'DATE',
                    label: 'Date',
                },
                {
                    id: 'LIST',
                    label: 'List',
                },
                {
                    id: 'RESOURCE',
                    label: 'Resource',
                },
                {
                    id: 'CURRENCY',
                    label: 'Currency',
                },
                {
                    id: 'TIMEZONE',
                    label: 'Timezone',
                },
            ],
            question: {
                uuid: '',
                variableName: '',
                type: '',
                title: '',
                helpText: '',
                min: null,
                max: null,
                choices: [],
                multiple: false,
                filterBy: 'Type',
                filter: [],
                tableFilter: '',
                defaultValue: null,
                isAdvanced: false,
            },
            choicesValid: {},
            filterByOptions: ['Type', 'Subtype', 'Custom Query Name'],
            disabledValue: null,
        }
    },
    computed: {
        choiceItems() {
            return Object.values(this.question.choices.map((el, index) => ({...el, index})))
        },

        types() {
            return [...collectTypes, ...processTypes, ...reportTypes]
        },

        resourceFilterOptions() {
            const allTypes = this.types.map(el => ({type: el.type, subtype: el.subtype}))

            if (this.question.filterBy === 'Subtype') {
                return allTypes.map(el => ({label: `${el.type} ${el.subtype}`, id: `${el.type}/${el.subtype}`}))
            }
            if (this.question.filterBy === 'Type') {
                return allTypes
                    .filter((el, idx) => allTypes.findIndex(type => type.type === el.type) === idx)
                    .map(el => ({label: el.type, id: el.type}))
            }

            return []
        },

        variableType() {
            if (this.question.type === 'NUMBER') return 'number'
            if (this.question.type === 'TEXT') return 'text'
            if (this.question.type === 'DATE') return 'date'
            if (this.question.type === 'LIST' && !this.question.multiple) return 'select'
            if (this.question.type === 'RESOURCE' && !this.question.multiple) return 'select'
            if (this.question.type === 'RESOURCE' && !this.question.multiple) return 'select'
            if (this.question.multiple) return 'treeselect'
            if (this.question.type === 'CURRENCY') return 'select'
            if (this.question.type === 'TIMEZONE') return 'select'
        },

        questionOptions() {
            if (this.question.type === 'LIST') {
                return this.question.choices.map(el => ({id: el.label, ...el}))
            }

            if (this.question.type === 'CURRENCY') {
                return currencies.map(el => ({id: el.code, label: `${el.code} (${el.name})`}))
            }

            if (this.question.type === 'TIMEZONE') {
                return process.env.VUE_APP_TIMEZONES.split(',').map(el => ({id: el, label: el}))
            }

            return []
        },
    },
    watch: {
        value() {
            if (!isEqual(this.question, this.value)) {
                this.question = {...this.question, ...this.value}
            }
        },
    },
    created() {
        this.question = {...this.question, ...this.value}
        this.emitUpdate()
    },
    methods: {
        removeQuestion() {
            this.$emit('remove', {...this.question})
        },

        addChoice() {
            this.question.choices.push({
                uuid:
                    Math.random()
                        .toString(36)
                        .substring(2, 15) +
                    Math.random()
                        .toString(36)
                        .substring(2, 15),
                label: null,
                name: null,
                value: '',
                questions: [],
                nestedQuestions: false,
            })

            this.emitInput()
        },

        removeChoice(choice) {
            this.question.choices.splice(
                this.question.choices.findIndex(el => el.uuid === choice.uuid),
                1
            )

            this.emitInput()
        },

        emitUpdate() {
            this.$emit('update:valid', !this.$v.$invalid)
        },

        emitInput() {
            this.$emit('input', this.question)
            this.emitUpdate()
        },

        setChoices() {
            this.$set(this.question.choices, 0, this.question.choices[0])
        },

        validateRef(ref) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)

            return validateField.$dirty ? !validateField.$invalid : null
        },
    },
}
</script>

<style></style>
